var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"_nOx8ejS5gwcF4jrTd30Q"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';

// Retrieve the base URL and environment variables
const baseURL = process.env.NEXT_PUBLIC_URL;
const isProd = process.env.NODE_ENV === 'production';

// Determine if the environment is local
const isLocal = baseURL.includes('localhost');

// Enable Sentry only if not local and in production
const enableSentry = !isLocal && isProd;
const dsn = process.env.NEXT_PUBLIC_SENTRY_DSN;
console.log('Sentry isRelease:', enableSentry);

if (dsn) {
  Sentry.init({
    dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
    // Adjust this value in production, or use tracesSampler for greater control
    tracesSampleRate: 1,
    // Setting this option to true will print useful information to the console while you're setting up Sentry.
    debug: isLocal ? true : false,
    enabled: enableSentry,
    // Next public environment variable is used to determine the environment
    // Added from CI/CD pipeline
    environment: process.env.NEXT_PUBLIC_SENTRY_ENVIRONMENT,

    // Integrations
    integrations: [
      Sentry.replayIntegration({
        // Don't mask text info in replays - just input fields
        maskAllText: false,
        maskAllInputs: true,
        // Capture network requests
        networkDetailAllowUrls: [window.location.origin],
      }),
    ],
    // Session Replay - replayIntegration()
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
}
