import '../styles/globals.scss';
import type { AppProps } from 'next/app';
import { appWithTranslation } from 'next-i18next';
import type { ReactElement, ReactNode } from 'react';
import type { NextPage } from 'next';
import { SWRConfig } from 'swr';
import { Mulish } from 'next/font/google';
import PlausibleProvider from 'next-plausible';

type NextPageWithLayout = NextPage & {
  GetLayout?: (page: ReactElement) => ReactNode;
};

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
};

export const mulish = Mulish({ subsets: ['latin'], variable: '--font-mulish' });

function Pro6PPWeb({ Component, pageProps }: AppPropsWithLayout) {
  // Use the layout defined at the page level, if available
  const GetLayout = Component.GetLayout ?? ((page) => page);

  return (
    <div className={`${mulish.variable} wrapper font-sans`}>
      {GetLayout(
        <PlausibleProvider
          domain="pro6pp.nl"
          customDomain="https://plausible.d-centralize.nl"
          selfHosted
        >
          <SWRConfig
            value={{
              fetcher: (resource, init) =>
                fetch(process.env.NEXT_PUBLIC_V2_API_URL + resource, init).then((res) =>
                  res.json(),
                ),
            }}
          >
            <Component {...pageProps} />
          </SWRConfig>
        </PlausibleProvider>,
      )}
    </div>
  );
}

export default appWithTranslation(Pro6PPWeb);
